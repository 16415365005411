<template>
  <div class="contacts_container">
    <MemberTitle :memberTitle="'仟源联系人'"/>
    <div class="contacts_info">
      <div class="title">仟源联系人</div>
      <div class="bottom"></div>
      <!-- <div class="table">
        <el-table :data="contact.data">
          <el-table-column prop="lxrname" label="姓名" width="180" />
          <el-table-column prop="phone" label="联系方式" width="180" />
        </el-table>
      </div> -->
    </div>
  </div>
</template>

<script>
import {reactive,onMounted,getCurrentInstance} from 'vue'
import MemberTitle from '../../../components/MemberTitle.vue'
export default {
  name:'contacts',
  components:{MemberTitle},
  setup(){
    const { proxy } = getCurrentInstance()   //引入原型代理
    const contact = reactive({data:[]})  //仟源联系人信息
    const contrlar = reactive({data:[]})   //合同信息
    //获取联系人信息
    const getContactInfo = async() => {
      const ret = await proxy.$get('v3/member/front/member/getInfo')
      contact.data = ret.data.qianYuanContactVOList
    }
    //获取销售合同
    const getContraler = () => {

    }
    onMounted(() => {
      getContactInfo()
      getContraler()
    })
    return{
      contact
      }
  }
}
</script>

<style lang="scss" scoped>
.contacts_container{
  padding-top: 20px;
  padding-left: 50px;
  .member_title_breadcrumb{
    width: 1010px;
    float: left;
    border: 1px solid rgba(187, 187, 187, .25);
    margin-left: 20px;
    .el-breadcrumb__inner{
      color: #101010 !important;
    }
  }
  .contacts_info{
    width: 1010px;
    height: 800px;
    background-color: #fff;
    float: left;
    border: 1px solid rgba(187, 187, 187, .25);
    border-top:0;
    margin-left: 20px;
    .title{
      color: #101010;
      font-size: 18px;
      font-weight: bold;
      font-family: SourceHanSansSC-bold;
      margin-top: 20px;
      margin-left: 58px;

    }
    .bottom{
      width: 909px;
      height: NaNpx;
      border: 1px solid rgba(187, 187, 187, 0.25);
      margin: 0 auto;
      margin-top: 22px;
    }
    .table{
      width: 364px;
      border: 1px solid rgba(187, 187, 187, 0.25);
      /* overflow: scroll; */
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}
</style>